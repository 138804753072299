import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import Preview from '../../components/Preview'
import WorkTitle from '../../components/WorkTitle'
import WorkTitleBlock from '../../components/WorkTitleBlock'

import styles from './mirmeda.module.scss'

export const frontmatter = {
  title: 'World of Honey Site',
  client: 'Mir Meda',
  type: 'web',
  path: '/works/web/mir-meda-site/',
  date: '2014-03-20',
  image: 'mirmeda-demo',
  favorite: true,
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/mir-meda/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <div className={styles.hero}>
        <div className={styles.heroContent}>
          <WorkTitle title={frontmatter.title} />

          <p className="aligncenter text-center">
            Design and build a website for a large retailer specializing on
            honey and bee products.
          </p>
          <figure className="text-center" style={{ margin: '0 0 100px 0' }}>
            <Preview
              fluid={findImage(images, 'mirmeda-home')}
              url="World of Honey"
              alt="Home Page"
            />
          </figure>
        </div>

        <div className={styles.heroBg}>
          <Img fluid={findImage(images, 'mirmeda-bg')} alt="" />
        </div>
      </div>

      <div style={{ background: '#fff', zIndex: '3', position: 'relative' }}>
        <WorkTitleBlock {...frontmatter} />
      </div>
    </Layout>
  )
}
